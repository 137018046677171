import './styleCard.css'
import { isValidURL, percent } from './auxFunctions'
import BackdropImageSlider from './Backdrop'
import React from 'react'
import { Divider } from '@mui/material'


const CardMine=(inf)=>{

    const [images,setImages]=React.useState(false)
    const [currImg, setCurrImg] = React.useState(0)
    const[errorImage,setErrorImage]=React.useState(false)
    //console.log(inf)
    
    const cargarImagenPorDefecto=(e)=>{
        e.target.src= 'https://negocio.saldogrin.com/imagen-no-encontrada.png';
        setErrorImage(true)
       
    }
    var slides=inf.prod.imagenes;
	var isRebaja=false;
    var Iimage=null;
    var precioMod=Number(inf.prod.precio);
    var precioEur=Number(String(inf.prod.precio_eur)).toFixed(2)


    const handleClick=()=>{
        setImages(true)
    }
    // verificando si el producto esta en rebaja
    if(inf.prod.modificacion_precio){
       var precioNew=Number(inf.prod.modificacion_precio)
       var precioNewEuro=Number(String(inf.prod.modificacion_precio_eur)).toFixed(2);
       if(precioNew < precioMod )
        {
            isRebaja=true;
        }
        else{
            precioMod=precioNew;
            precioEur=precioNewEuro;
        }
    }



    
    var imgUrl=slides[0]
    console.log(inf.prod)
    return(
      <div class="card" style={{width:'100%', maxWidth:'600px',position:'relative'}} onClick={handleClick} key={inf.index}>
        {isRebaja?<div><img src='NEW icon 3 NARANJA.png' style={{position: 'absolute',height:'auto', width:'15%'}}></img>
            <div className='rebaja-component'>
            <span style={{margin:'3px'}}>-{percent(precioMod,precioMod-precioNew)}%</span>
        </div></div>:null}
        <img class="responsive-image" src={slides.length>0? imgUrl :'https://negocio.saldogrin.com/imagen-no-encontrada.png'} onError={cargarImagenPorDefecto}  ></img>
        <div class="container" style={{display: 'grid'}}>
        <h4 class="responsive-text-Title"><b>{inf.prod.descripcion}</b></h4>
            {inf.prod.descripcion_largo?
                <div>
            <Divider/>
                <span class="responsive-text" style={{margin:0,textDecoration: 'none', color :'black'}}>
            <span class="responsive-text" style={{margin: 0}}><b>Descripción: </b><span
                style={{marginRight: '3px', color: 'GrayText'}}> {inf.prod.descripcion_largo}</span> </span>
        </span>
        </div>
          :null}
            {inf.prod.descripcion_largo?null:<p></p>}

        <div className='precios' style={{display:'flex'}}>
            <div>
        <Divider/>
                
                {isRebaja?<span class="responsive-text" style={{margin:0,textDecoration: 'none', color :'black'}}> 
                    <span class="responsive-text" style={{margin:0}}><b>Precio CUP: </b><span style={{textDecoration: 'line-through', marginRight:'3px', color:'GrayText' }}>${precioMod}</span> </span> 
                    ${precioNew}
                </span>
                
                :

                precioMod ? <span class="responsive-text" style={{margin:0,textDecoration: 'none', color :'black'}}> 
                        <b>Precio CUP: </b>${precioMod}
                    </span>
                    :
                    null
                }
                
                <br/>
                
                {isRebaja?<span class="responsive-text" style={{margin:0,textDecoration: 'none',color:'green'}}> 
                    <span class="responsive-text" style={{margin:0}}><b>Precio $G: </b><span style={{textDecoration: 'line-through',color:'GrayText', marginRight:'3px'}}>${precioEur} </span></span> 
                    ${precioNewEuro}
                </span>
                
                :

                <span class="responsive-text" style={{margin:0,textDecoration: 'none',color:'green'}}><b>Precio $G: </b>${precioEur}</span>
                }
            </div>
            <div className='rebaja'>

            </div>
            
        </div>
      
      </div>
        {(images&&slides.length>0&&!errorImage)?<BackdropImageSlider slides={slides} setIsOpen={setImages} isOpen={images} index={inf.index} currImg={currImg} setCurrImg={setCurrImg}/>:null}
  </div>
    )
}

export default CardMine