import './BusinessDep.css'
import { Divider } from '@mui/material';
import BotonComp from './BotonCompra';



const BusinessDep=({nombreN,telefono,horarioINI,horarioFIN,direccion,paginaWeb,logo})=>{
   
  const hasImage = logo!=null
  const logoBase64= "data:image/png;base64,"+logo
  return(
    <div class="informations-container" style={{alignContent:'center',alignItems:'center',justifyContent:'center'}}>
      <div style={{display:'flex',justifyContent:'center', alignItems: 'center'}}>
      {hasImage?<img src={logoBase64} style={{width:'60%'}}></img>:null}
      </div>
      <h2 class="title">{nombreN} </h2>
      <Divider/>
      <p class = "sub-title" style={{margin:1}}><b>Teléfono: </b>{telefono}</p>
      <p class = "sub-title" style={{margin:1}}><b>Horario: </b>{horarioINI} a {horarioFIN}</p>
      <Divider/>
      <p class = "sub-title" style={{margin:1}}><b>Direccion: </b>{direccion}</p>
      <Divider/>
      <BotonComp paginaWeb={paginaWeb}/>      
    </div>
    )
}

export default BusinessDep;