import * as React from "react";
import Box from "@mui/material/Box";
import './main.css'
import { BrowserRouter, Route, Link } from 'react-router-dom'
import { Toolbar } from "@mui/material";
import { VariablesProvider } from "./Context";
import Routess from "./Routers";



export default function MainApp() {

  const [width, setWidth] = React.useState(window.innerWidth);
  const breakpoint = 620;

  React.useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth)
    window.addEventListener("resize", handleWindowResize);

    // Return a function from the effect that removes the event listener
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);
  

  
  
  return (
    <VariablesProvider>
      <BrowserRouter>
        <Box>
        
          <Box component="main" >
            <Toolbar style={{paddingTop: ((width<breakpoint)? '56px':'24px')}}/>
            <Routess />
          </Box>
          
        </Box>
      </BrowserRouter>
    </VariablesProvider>
  );
}
